import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Divider, Checkbox } from 'antd';
import LoginStyle from './index.style';
import Logo from '../../assets/Logo/index.logo';
import { useApp } from '../../contexts/app';
import localStorages from '../../utils/localStorages'; 
import { Navigate, useNavigate } from 'react-router-dom';
import { SET_USER } from '../../contexts/types';
import loginServices from './index.service';
import { notifySuccess, notifyError } from '../../utils';
import { KeyConstants } from '../../utils/contants/key-constants';

const Login = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [state, dispatch] = useApp();
    const [remember, setRemember] = useState(false);
    const [initialValues, setInitialValues] = useState({
        username: '',
        password: '',
        remember: true,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const userCookie = localStorages().cookies.get(KeyConstants.APP_USER_COOKIE); 
        const savedUser = localStorages().cookies.get(KeyConstants.APP_USER_SAVED); 
        const token = localStorages().getToken(); 
        if (userCookie) {
            setInitialValues(JSON.parse(userCookie));
        }
        if (token && savedUser) {
            dispatch({ type: SET_USER, payload: savedUser });
            navigate('/dashboard'); 
        }
    }, [dispatch, navigate]);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('email', values.username);
            formData.append('password', values.password);

            const result = await loginServices.login(formData);

            if (result.response?.code === 200) {
                notifySuccess('Berhasil Login');
                
                localStorages().saveToken(result.response.token);

                if (remember) {
                    localStorages().cookies.save(KeyConstants.APP_USER_COOKIE, JSON.stringify(values), { expires: 7 }); 
                } else {
                    localStorages().cookies.remove(KeyConstants.APP_USER_COOKIE);
                }

                const user = {
                    username: result.response.name,
                    token: result.response.token,
                    role: result.response.role,
                    menu: result.response.menu,
                };

                dispatch({ type: SET_USER, payload: user });
                localStorages().cookies.save(KeyConstants.APP_USER_SAVED, user, { expires: 7 }); 

                setLoading(false);
                navigate('/dashboard');
            } else {
                throw new Error(result.response.message);
            }
        } catch (error) {
            setLoading(false);
            notifyError(error.message || 'Login failed');
        }
    };

    const onChangeCheckbox = () => {
        setRemember(!remember);
    };

    // If user is already logged in, redirect to dashboard
    if (state?.user) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <LoginStyle>
            <Logo />
            <div className="container">
                <h4>Login Form</h4>
                <Divider />
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={initialValues}
                    layout="vertical"
                >
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[{ required: true, message: 'Username harus diisi' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: 'Password harus diisi' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                    >
                        <Checkbox
                            checked={remember}
                            onChange={onChangeCheckbox}
                        >
                            Remember me
                        </Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                        >
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </LoginStyle>
    );
};

export default Login;
