import { createContext, useContext, useEffect, useReducer } from 'react';
import { INITIAL_STATE, appReducer } from './app.reducer';
import localStorages from '../utils/localStorages';
import { KeyConstants } from '../utils/contants/key-constants';

const AppStateContext = createContext();
const AppDispatchContext = createContext();

function useApp() {
    const state = useContext(AppStateContext);
    const dispatch = useContext(AppDispatchContext);
    return [state, dispatch];
}

function getInitialState() {
    var value = localStorages().get(KeyConstants.APP_STATE);
    if (value) {
        return value;
    }
    return INITIAL_STATE;
}

export default function AppProvider(props) {
    const [state, dispatch] = useReducer(appReducer, getInitialState());
    useEffect(() => {
        try {
            localStorages().save(KeyConstants.APP_STATE, JSON.stringify(state));
        } catch(e) {}
    }, [state]);

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                {props.children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
}

export { useApp, AppStateContext, AppDispatchContext };
